import React from "react";
import { connect } from "react-redux";
import { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import classnames from "classnames";
import NavigationActions from "redux/navigation/actions";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import Pagination from "@mui/material/Pagination";

const { success, error } = NavigationActions;
const Staffservicetable = props => {
  const { staffData, loading } = props;
  const HeaderComponent = props => {
    let classes = {
      "my-2": true,
      "mx-3": true,
      "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
      "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
    };
    return <div className={classnames(classes)}>{props.title}</div>;
  };
  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Staff-Image"
            />
          );
        },
        placeholder: "staff_image",
        accessor: "file_image",
        Cell: tableInstance => (
          <span>
            <img
              src={`${process.env.REACT_APP_UPLOAD_DIR}${tableInstance.row.original?.file_image}`}
              className="profile-img"
              alt="no"
              width={60}
            />
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Staff-Name"
            />
          );
        },
        placeholder: "staff_name",
        accessor: "name",
        Cell: tableInstance => <span>{tableInstance.row.original?.name}</span>
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Staff-Email"
            />
          );
        },
        placeholder: "staff_email",
        accessor: "email",
        Cell: tableInstance => <span>{tableInstance.row.original?.email}</span>
      }
    ],
    [staffData]
  );
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups
  } = useTable(
    {
      data: staffData,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useSortBy,
    usePagination
  );
  return (
    <ReactTableWrapper {...props}>
      <div className="roe-card-style mt-15 mb-30">
        <div className="roe-card-header">
          <span className="hash"># </span> Room-Staff
        </div>
        <div className="table-container text-left overflow-auto mt-4">
          <table
            border={1}
            className="custom-react-table-theme-class"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => (
                    <th
                      {...header.getHeaderProps(header.getSortByToggleProps())}
                    >
                      <div>{header.render("Header")}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {/* {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(header => {
                                            return (
                                                <td
                                                    {...header.getHeaderProps(
                                                        header.getSortByToggleProps()
                                                    )}
                                                >
                                                    <div>
                                                        {header.canFilter ? header.render("Filter") : null}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))} */}
              {staffData.length > 0
                ? page?.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                : !loading && (
                    <tr>
                      {
                        <td className="mt-4 text-left font-weight-bold">
                          <h5>No data found</h5>
                        </td>
                      }
                    </tr>
                  )}
            </tbody>
          </table>
        </div>
        <div className="float-right" style={{ display: "none" }}>
          <Pagination
            // onPageChange={gotoPage}
            pages={1}
            // page={pageIndex}
            // count={pageCount}
            onChange={(e, val) => {
              // setPageCng(val);
            }}
          />
        </div>
      </div>
    </ReactTableWrapper>
  );
};
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, { success, error })
)(Staffservicetable);
