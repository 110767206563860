import {
    withFormik
} from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        name: Yup.string().required('Please Enter Name'),
        file_image: Yup.string().required('Please upload image'),
        description: Yup.string().required('Please Enter description'),
        status: Yup.string().required('Please select status'),
    }),
    validateOnMount: true,
    mapPropsToValues: props => ({
        name: '',
        file_image: '',
        description: '',
        status: true,
    }),
    handleSubmit: (values) => { },
    displayName: 'CustomValidationForm',
    enableReinitialize: true,
});

export default formikEnhancer;