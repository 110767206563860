import { connect } from "react-redux";
// import PageTitle from 'components/common/PageTitle';
import React, { useState, useMemo, useEffect, useCallback } from "react";
// import { rowData } from "util/data/reactTableData";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import Pagination from "@mui/material/Pagination";
import classnames from "classnames";
import { Eye } from "react-feather";
// import Pagination from "components/common/Pagination";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import RoomModal from "./RoomModal";
import enhancer from "./enhancer/RoomEnhancer";
import { compose } from "redux";
import {
  getRoom,
  deleteRoom,
  changeRoomStatus,
  send_room_key,
  getRoomsByVenue
} from "services/roomServices";
import NavigationActions from "redux/navigation/actions";
import { Modal } from "reactstrap";
import ConformationModaluser from "components/common/ConformationModalUser";
import { Switch } from "@mui/material";
import { withRouter } from "react-router-dom";
import Loader from "components/Loader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const { success, error } = NavigationActions;

const RoomListByVenue = props => {
  const { sidebarTheme, token, history } = props;
  let { id } = useParams();

  // console.log(token, "token")

  const activeColor = {
    color: sidebarTheme.activeColor
  };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  // const [selectedRow, setSelectedRow] = useState({});
  const [openDeleteModal, toggleDeleteModalOpen] = useState();
  const [deleteId, setDeleteID] = useState("");
  const [roomList, setRoomList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState();
  const [pageCng, setPageCng] = useState(1);
  const [show, setShow] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  // const [accessModal, accessModalOpen] = useState();
  // const [keyId, setKeyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [refresh, toggleRefresh] = useState(true);
  const [sortObject, setSortObject] = useState({});

  // const [deleteClick, setDeleteClick] = useState(false);

  const getRoomHandler = useCallback(async () => {
    setLoading(true);
    const data = {
      limit: 10,
      page: pageCng,
      search: search === undefined ? "" : search,
      sortBy: sortBy?.id,
      sortOrder: sortBy?.desc ? -1 : 1
    };
    await getRoomsByVenue(token, id, data)
      .then(res => {
        if (res.success) {
          setRoomList(res.data.docs);
          setPageCount(res.data.totalPages);
          setDataLength(res.data.docs.length);
          setLoading(false);
          toggleRefresh(false);
        } else {
          error(res.message);
          setLoading(false);
        }
      })
      .catch(err => {
        error(err.message);
      });
  }, [pageCng, search]);

  const chageStatus = (status, id) => {
    setLoading(true);
    setShow(false);
    changeRoomStatus(token, id, { status: status })
      .then(res => {
        if (res.success) {
          success(res.message);
          toggleRefresh(true);
        } else {
          error(res.message);
        }
        setShow(true);
        setLoading(false);
      })
      .catch(err => {
        setShow(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (search !== undefined) {
      const timeOutId = setTimeout(() => getRoomHandler(), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Image"
            />
          );
        },
        placeholder: "image",
        accessor: "file_image",
        Cell: tableInstance => (
          <span
            onClick={() => {
              history.push(`/room/${tableInstance.row.original?._id}`);
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              src={`${process.env.REACT_APP_UPLOAD_DIR}${tableInstance.row.original?.file_image}`}
              className="profile-img"
              alt="no"
              width={60}
            />
            {/* {console.log(tableInstance.row.original, "check188")} */}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Name"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Names",
        accessor: "name",
        Cell: tableInstance => (
          <span className="text-capitalize">
            {tableInstance.row.original?.name}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class">
              <button
                className="react-table-view-button"
                onClick={() => {
                  history.push(`/room/${tableInstance.row.original?._id}`);
                }}
              >
                <Eye style={{ width: "20px", height: "20px" }} />
              </button>
            </div>
          );
        }
      }
    ],
    [roomList]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    // pageCount,
    gotoPage,
    state: { sortBy }
  } = useTable(
    {
      data: roomList,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    refresh && getRoomHandler();
  }, [refresh]);
  useEffect(() => {
    setSortObject({ ...sortBy[0] });
    toggleRefresh(true);
  }, [sortBy, pageCng]);
  return (
    <>
      <div>
        {/* <PageTitle title="sidebar.Countries" /> */}
        <div className="plr-15">
          <div className="mtb-30 theme-color">
            <div className="row">
              <div className="col-10">
                <div className="introduction" style={activeColor}>
                  Rooms
                </div>
              </div>
              {/* <div className="col-2">
                <div style={{ width: "initial" }}>
                  <button
                    type="submit"
                    className="c-primary btn btn-secondary w-100 addbtncolor"
                    onClick={() => {
                      setIsEdit(false);
                      setModal(true);
                    }}
                  >
                    Add Room
                  </button>
                </div>
              </div> */}
            </div>

            <div className="row">
              <div className="col-4">
                <input
                  className="form-control react-form-input mt-2"
                  type="search"
                  placeholder="Search Room"
                  onChange={e => {
                    setPageCng(1);
                    setSearch(e.target.value);
                    // toggleRefresh(true);
                  }}
                />
              </div>
              <div className="col-12">
                <ReactTableWrapper {...props}>
                  <div className="table-container text-left overflow-auto mt-4">
                    <table
                      border={1}
                      className="custom-react-table-theme-class"
                      {...getTableProps()}
                    >
                      <thead>
                        {headerGroups.map(headerGroup => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(header => (
                              <th
                                className="text-center"
                                {...header.getHeaderProps(
                                  header.getSortByToggleProps()
                                )}
                              >
                                <div>{header.render("Header")}</div>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      {loading ? (
                        <tbody>
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody {...getTableBodyProps()}>
                          {roomList.length > 0 ? (
                            page.map(row => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map(cell => (
                                    <td
                                      className="text-center"
                                      {...cell.getCellProps()}
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  ))}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={3}
                                className="mt-4 text-center text-bold"
                              >
                                <h5>No data found</h5>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="float-right">
                    <Pagination
                      page={pageCng}
                      count={pageCount}
                      onChange={(e, val) => {
                        setPageCng(val);
                        toggleRefresh(true);
                      }}
                    />
                  </div>
                </ReactTableWrapper>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={modal} backdrop={true}>
          {modal && (
            <RoomModal
              isEdit={isEdit}
              editData={editData}
              onClose={() => {
                setModal(false);
                setIsEdit(false);
                setEditData({});
              }}
              toggleRefresh={e => toggleRefresh(e)}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(RoomListByVenue);
