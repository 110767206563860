import React, { useEffect, useState } from "react";
import { loginBack } from "helper/constant";
import { useParams } from "react-router-dom";
import NavigationActions from "redux/navigation/actions";
import { changerequestfromweb, requestData } from "services/requestServices";
import { Modal } from "reactstrap";
import Loader from "components/Loader";
import approvedImage from "../../../assets/images/Group 571.svg";

const { success, error } = NavigationActions;

export const Request = props => {
  const { staff_id, room_id, service_id, request_id } = useParams();
  const [roomName, setRoomname] = useState("");
  const [staffName, setStaffname] = useState("");
  const [serviceName, setServicename] = useState("");
  const [click, setClick] = useState(false);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState();
  // const [escalate, setEscalate] = useState();

  const getRequestData = async () => {
    setLoading(true);
    await requestData({
      room_id: room_id,
      staff_id: staff_id,
      service_id: service_id,
      request_id: request_id
    }).then(res => {
      // console.log(res, "check31");
      if (res.success) {
        // if (res.data === "escalated") {
        //   setEscalate(true);
        //   setLoading(false);
        // }
        if (res.data === true) {
          setApproved(true);
          setLoading(false);
        } else {
          setRoomname(res.data.roomName);
          setStaffname(res.data.staffName);
          setServicename(res.data.serviceName);
          setLoading(false);
        }
      } else {
        error(res.data.message);
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    document.getElementById("project_title").innerHTML = "MAV";
    getRequestData();
  }, []);
  const approveorreject = async () => {
    setLoading(true);
    await changerequestfromweb({
      staff_id: staff_id,
      room_id: room_id,
      service_id: service_id,
      request_id: request_id,
      comment: comment
    }).then(val => {
      if (val.success) {
        success(val.message);
        setApproved(true);
        setLoading(false);
        setClick(false);
      } else {
        error(val.message);
        setLoading(false);
        setClick(false);
      }
    });
  };
  useEffect(() => {
    click && approveorreject();
  }, [click]);
  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid" style={loginContainer}>
          <div className="form-container p-5" style={{ width: "440px" }}>
            {/* {console.log(approved, "check87")} */}
            {//escalate ? (
            //<div className="approveddiv">
            //<p className="mt-3">Request Escalate</p>
            //</div>
            // ) :
            approved ? (
              <>
                <div className="approveddiv">
                  <img src={approvedImage} alt="approveimage logo" />
                  <p className="mt-3">Request Accepted Successfully!</p>
                </div>
              </>
            ) : (
              roomName !== "" &&
              staffName !== "" &&
              serviceName !== "" && (
                <>
                  <div className="rquesttittle">Hello {staffName}</div>
                  <p className="my-2">
                    Room Number : {roomName} made a request
                  </p>
                  <p className="my-2">Service Name : {serviceName}</p>
                  <div>
                    <label>Comment :</label>
                    <textarea
                      className="w-100 requesttextarea"
                      onChange={e => {
                        setComment(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <button
                      className="btn approvebtn"
                      onClick={() => {
                        setClick(true);
                      }}
                    >
                      Accept
                    </button>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};
