import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    thresholdtime: Yup.string()
      .required("Please Enter Time Value")
      .matches(/^[0-9]+$/, "Please enter valid Time")
  }),
  validateOnMount: true,
  mapPropsToValues: props => ({
    thresholdtime: null
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
