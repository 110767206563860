import { connect } from "react-redux";
// import PageTitle from 'components/common/PageTitle';
import React, { useState, useMemo, useEffect, useCallback } from "react";
// import { rowData } from "util/data/reactTableData";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import Pagination from "@mui/material/Pagination";
import classnames from "classnames";
// import Pagination from "components/common/Pagination";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import StaffModal from "./StaffModal";
import enhancer from "./enhancer/StaffEnhancer";
import { compose } from "redux";
import {
  getStaff,
  deleteStaff,
  changeStaffStatus
} from "services/staffServices";
import NavigationActions from "redux/navigation/actions";
import { Modal } from "reactstrap";
import ConformationModaluser from "components/common/ConformationModalUser";
import { Switch } from "@mui/material";
import { send_access_key } from "services/adminServices";
import Loader from "components/Loader";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const { success, error } = NavigationActions;

const StaffList = props => {
  const { sidebarTheme, token } = props;
  // console.log(token, "token")

  const activeColor = {
    color: sidebarTheme.activeColor
  };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDeleteModal, toggleDeleteModalOpen] = useState();
  const [accessModal, accessModalOpen] = useState();
  const [deleteId, setDeleteID] = useState("");
  const [staffList, setStaffList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState();
  const [pageCng, setPageCng] = useState(1);
  const [show, setShow] = useState(true);
  const [keyId, setKeyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [refresh, toggleRefresh] = useState(true);
  const [dataLength, setDataLength] = useState(0);

  const getStaffHandler = useCallback(async () => {
    setLoading(true);
    const data = {
      limit: 10,
      page: pageCng,
      search: search === undefined ? "" : search
    };
    await getStaff(token, data)
      .then(res => {
        setStaffList(res.data.docs);
        // console.log(res.data.docs, "staffdata");
        setPageCount(res.data.totalPages);
        setDataLength(res.data.docs.length);
        toggleRefresh(false);
        setLoading(false);
      })
      .catch(err => {
        error(err.message);
        setLoading(false);
      });
    //eslint-disable-next-line
  }, [pageCng, search]);
  // useEffect(() => {
  //   getStaffHandler();
  // }, [pageCng, search, show]);
  useEffect(() => {
    refresh && getStaffHandler();
    //eslint-disable-next-line
  }, [refresh]);
  useEffect(() => {
    if (search !== undefined) {
      const timeOutId = setTimeout(() => getStaffHandler(), 1000);
      return () => clearTimeout(timeOutId);
    }
    //eslint-disable-next-line
  }, [search]);
  // const getStaffHandler = async () => {
  //     await getStaff().then(res => {
  //         console.log("res", res.data);
  //         if (res.success) {
  //             settableData(res.data);
  //             // success(res.data);
  //         }
  //     });
  // };

  const deleteStaffHandler = async () => {
    setLoading(true);
    await deleteStaff(token, deleteId).then(res => {
      if (res.success) {
        success(res.message);
        toggleDeleteModalOpen(false);
        setPageCng(
          dataLength === 1 ? (pageCng !== 1 ? pageCng - 1 : pageCng) : pageCng
        );
        toggleRefresh(true);
      } else {
        error(res.message);
      }
      setLoading(false);
    });
  };

  const chageStatus = (status, id) => {
    setLoading(true);
    setShow(false);
    // console.log("status:", status, id);
    changeStaffStatus(token, id, { status: status })
      .then(res => {
        // console.log(res, "res1233123")
        if (res.success) {
          success(res.message);
          toggleRefresh(true);
        } else {
          error(res.message);
        }
        setShow(true);
        setLoading(false);
      })
      .catch(err => {
        // error(err.response.data.message);
        setShow(true);
        setLoading(false);
      });
  };

  const sendKeyHandler = async id => {
    // console.log(id, "id")
    setLoading(true);
    await send_access_key(token, { id: id })
      .then(res => {
        // console.log(res, "res")
        // const val = (res.data.data);
        // console.log("res", val[0]);
        if (res.success) {
          success(res.message);
          accessModalOpen(false);
          setLoading(false);
        } else {
          error(res.message);
          setLoading(false);
        }
      })
      .catch(err => {
        error(err.message);
        setLoading(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Image"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "image",
        accessor: "image",
        Cell: tableInstance => (
          <span>
            <img
              src={`${process.env.REACT_APP_UPLOAD_DIR}${tableInstance.row.original?.file_image}`}
              className="profile-img"
              alt="no"
              width={60}
            />
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Name"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Name",
        accessor: "name",
        Cell: tableInstance => (
          <span className="text-capitalize">
            {tableInstance.row.original?.name}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Email"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Email",
        accessor: "email",
        Cell: tableInstance => <span>{tableInstance.row.original?.email}</span>
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Phone"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Phone",
        accessor: "phone",
        Cell: tableInstance => <span>{tableInstance.row.original?.phone}</span>
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Status"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Status",
        disableFilters: true,
        disableSortBy: true,
        accessor: "status",
        Cell: tableInstance => (
          <span>
            {show && (
              <Switch
                checked={tableInstance?.row?.values?.status}
                onChange={e =>
                  chageStatus(
                    e.target.checked,
                    tableInstance?.row?.original?._id
                  )
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            )}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class">
              <button
                className="react-table-view-button"
                onClick={() => {
                  if (tableInstance.row.values.status === true) {
                    accessModalOpen(true);
                    setKeyId(tableInstance.row.original?._id);
                  } else {
                    error(
                      "To send access key, first activate the staff member"
                    );
                  }
                }}
              >
                <i className="fas fa-key" title="Access Key" />
              </button>
              <button
                className="react-table-edit-button"
                onClick={() => {
                  // console.log(tableInstance.row, "row", {
                  //     _id: tableInstance.row.original._id,
                  //     name: tableInstance.row.original.name,
                  //     email: tableInstance.row.original.email,
                  //     phone: tableInstance.row.original.phone,
                  //     file_image: tableInstance.row.original.file_image,
                  //     status: tableInstance.row.original.status
                  // })
                  setModal(true);
                  setIsEdit(true);
                  setEditData(tableInstance.row.original);
                }}
              >
                <i className="fas fa-edit" title="Edit" />
              </button>
              <button
                className="react-table-delete-button"
                onClick={() => {
                  toggleDeleteModalOpen(true);
                  setDeleteID(tableInstance.row.original?._id);
                }}
              >
                <i className="fas fa-trash" title="Delete" />
              </button>
            </div>
          );
        }
      }
    ],
    [staffList]
  );
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    // pageCount,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      data: staffList,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div>
        {/* <PageTitle title="sidebar.Countries" /> */}
        <div className="plr-15">
          <div className="mtb-30 theme-color">
            <div className="row">
              <div className="col-10">
                <div className="introduction" style={activeColor}>
                  Staff
                </div>
              </div>
              <div className="col-2">
                <div style={{ width: "initial" }}>
                  <button
                    type="submit"
                    className="c-primary btn btn-secondary w-100 addbtncolor"
                    onClick={() => {
                      setIsEdit(false);
                      setModal(true);
                    }}
                  >
                    Add Staff
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <input
                  className="form-control react-form-input mt-2"
                  type="search"
                  placeholder="Search Staff"
                  onChange={e => {
                    setPageCng(1);
                    setSearch(e.target.value);
                    // toggleRefresh(true);
                  }}
                />
              </div>
              <div className="col-12">
                <ReactTableWrapper {...props}>
                  <div className="table-container text-left overflow-auto mt-4">
                    <table
                      border={1}
                      className="custom-react-table-theme-class"
                      {...getTableProps()}
                    >
                      <thead>
                        {headerGroups.map(headerGroup => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(header => (
                              <th
                                {...header.getHeaderProps(
                                  header.getSortByToggleProps()
                                )}
                              >
                                <div>{header.render("Header")}</div>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      {loading ? (
                        <tbody>
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody {...getTableBodyProps()}>
                          {/* {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(header => {
                                            return (
                                                <td
                                                    {...header.getHeaderProps(
                                                        header.getSortByToggleProps()
                                                    )}
                                                >
                                                    <div>
                                                        {header.canFilter ? header.render("Filter") : null}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))} */}
                          {staffList.length > 0 ? (
                            page.map(row => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  ))}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              {
                                <td className="mt-4 text-left font-weight-bold">
                                  <h5>No data found</h5>
                                </td>
                              }
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="float-right">
                    <Pagination
                      // onPageChange={gotoPage}
                      // pages={pageCount}
                      page={pageCng}
                      count={pageCount}
                      onChange={(e, val) => {
                        setPageCng(val);
                        toggleRefresh(true);
                      }}
                    />
                  </div>
                </ReactTableWrapper>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={modal} backdrop={true}>
          {modal && (
            <StaffModal
              isEdit={isEdit}
              editData={editData}
              onClose={() => {
                setModal(false);
                setIsEdit(false);
                setEditData({});
              }}
              toggleRefresh={e => toggleRefresh(e)}
            />
          )}
        </Modal>
        <Modal isOpen={openDeleteModal} backdrop={true}>
          {openDeleteModal && (
            <ConformationModaluser
              isOpen={openDeleteModal}
              onClose={() => toggleDeleteModalOpen(false)}
              confirmText={"Delete"}
              message={"Are you sure you want to delete?"}
              handleConfirm={() => deleteStaffHandler()}
            />
          )}
        </Modal>
        <Modal isOpen={accessModal} backdrop={true}>
          {accessModal && (
            <ConformationModaluser
              isOpen={accessModal}
              onClose={() => accessModalOpen(false)}
              confirmText={"Send"}
              message={"Are you sure you want to send access key?"}
              handleConfirm={() => sendKeyHandler(keyId)}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

// const FilterComponent = tableInstance => {
//     const { filterValue, setFilter } = tableInstance.column;
//     return (
//         <input
//             type="text"
//             value={filterValue || ""}
//             onChange={e => {
//                 setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
//             }}
//             className="tabl-search react-form-input"
//             placeholder={`Search ${tableInstance.column.placeholder}`}
//             onClick={e => e.stopPropagation()}
//         />
//     );
// };

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(StaffList);
