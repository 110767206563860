import { connect } from "react-redux";
// import PageTitle from 'components/common/PageTitle';
import React, { useState } from "react";
// import { rowData } from "util/data/reactTableData";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import enhancer from "./enhancer/RoomEnhancer";
import Select from "react-select";
import { compose } from "redux";
import { getStaffAllData } from "services/staffServices";
import { addRoom, editRoom } from "services/roomServices";
import NavigationActions from "redux/navigation/actions";
import { useEffect } from "react";
import Loader from "components/Loader";
import { getServiceAllData } from "services/serviceServices";
import { Tag } from "react-feather";
import { VisibilityOff } from "@mui/icons-material";
import { Visibility } from "@mui/icons-material";
import { getAllVenues } from "services/venueServices";
const { success, error } = NavigationActions;

const RoomModal = props => {
  const { isEdit, onClose } = props;
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    token,
    setFieldValue,
    isValid,
    setValues,
    handleSubmit,
    toggleRefresh,
    editData
  } = props;
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [venueData, setVenueData] = useState([]);
  const [togglePassword, setTogglePassword] = useState(false);
  // const [rendomGenerate, setRendomGenerate] = useState(false);

  useEffect(() => {
    isEdit && setValues({ ...editData });
  }, [editData]);

  const getVenuesData = async () => {
    await getAllVenues(token)
      .then(res => {
        let venueOptions = res.data.map(venue => {
          return { value: venue._id, label: venue.name };
        });
        setVenueData(venueOptions);
      })
      .catch(err => {
        error(err.message);
      });
  };

  const getStaffData = async () => {
    await getStaffAllData(token)
      .then(res => {
        // console.log(res, "res");
        // const val = (res.data.data);
        // console.log("res", val[0]);
        let staffArr = res.data?.map((val, i) => {
          return { value: val._id, label: val.name };
        });
        setStaffData(staffArr);
      })
      .catch(err => {
        error(err.message);
      });
  };
  const getServiceData = async () => {
    await getServiceAllData(token)
      .then(res => {
        // console.log(res, "checkres");
        let serviceArr = res.data?.map((val, i) => {
          return { value: val._id, label: val.name };
        });
        setServiceData(serviceArr);
      })
      .catch(err => {
        error(err.message);
      });
  };
  useEffect(() => {
    getStaffData();
    getServiceData();
    getVenuesData();
  }, []);

  const getRandomPassword = () => {
    var length = 8,
      charset = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setFieldValue("password", retVal);
  };
  useEffect(() => {
    !isEdit && getRandomPassword();
  }, []);
  console.log("values", values);
  const RoomHandler = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      var formData = new FormData();
      // console.log(values.file_image, "image")
      formData.append("file_image", values.file_image);
      formData.append("name", values.name);
      formData.append("username", values.username.toLowerCase());
      formData.append("password", values.password);
      formData.append("staff_data", JSON.stringify(values.staff_data));
      formData.append("service_data", JSON.stringify(values.service_data));
      formData.append("venue_data", values.venue_data);
      formData.append("status", values.status);
      setLoading(true);

      isEdit
        ? await editRoom(token, values._id, formData).then(res => {
            if (res.success) {
              success(res.message);
              toggleRefresh(true);
              onClose();
              setLoading(false);
              // console.log(res, "edit response")
            } else {
              error(res.message);
              setLoading(false);
            }
            setLoading(false);
            setPreview(null);
          })
        : await addRoom(token, formData).then(res => {
            if (res.success) {
              // console.log("add response", res);
              success(res.message);
              toggleRefresh(true);
              onClose();
              setLoading(false);
            } else {
              error(res.message);
              setLoading(false);
            }
            setPreview(null);
            setLoading(false);
          });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Room
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="form-group">
            <label>
              Name <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              value={values.name}
              onBlur={handleBlur}
              placeholder="Room Name"
            />
            <Error field="name" />
          </div>
          <div className="form-group">
            <label>
              Username <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="username"
              onChange={handleChange}
              value={values.username}
              onBlur={handleBlur}
              placeholder="Username"
              disabled={isEdit ? true : false}
            />
            <Error field="username" />
          </div>
          <div className="form-group">
            <div className="row align-items-center">
              <div className="col">
                <label>
                  Password <span className="red">*</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    type={togglePassword ? "text" : "password"}
                    className="form-control react-form-input"
                    id="password"
                    onChange={handleChange}
                    value={values.password}
                    onBlur={handleBlur}
                    placeholder="Room password"
                  />
                  <div className="input-group-append">
                    {togglePassword ? (
                      <VisibilityOff onClick={() => setTogglePassword(false)} />
                    ) : (
                      <Visibility
                        onClick={() => {
                          setTogglePassword(true);
                          // console.log("clicked")
                        }}
                      />
                    )}
                  </div>
                </div>
                <Error field="password" />
              </div>
              <div className="col-auto">
                <button
                  className="generate_btn"
                  onClick={e => {
                    e.preventDefault();
                    getRandomPassword();
                  }}
                  title="Generate New Password"
                >
                  <Tag />
                </button>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>
              Staff <span className="red">*</span>
            </label>
            <Select
              value={staffData?.filter(val => {
                return values?.staff_data?.includes(val.value);
              })}
              isMulti
              name="staff_data"
              options={staffData}
              className="basic-multi-select w-100"
              classNamePrefix="select"
              onChange={selected => {
                // console.log(selected, "checkselected");
                if (selected && selected !== null) {
                  setFieldValue(
                    "staff_data",
                    selected.map(val => val.value)
                  );
                } else {
                  setFieldValue("staff_data", []);
                }
              }}
            />
            {/* {console.log(errors, "checkerrors")} */}
            <Error field="staff_data" />
          </div>
          <div className="form-group">
            <label>
              Services <span className="red">*</span>
            </label>
            <Select
              value={serviceData?.filter(val => {
                return values?.service_data?.includes(val.value);
              })}
              isMulti
              name="service_data"
              options={serviceData}
              className="basic-multi-select w-100"
              classNamePrefix="select"
              onChange={selected => {
                // console.log(selected, "checkselected");
                if (selected && selected !== null) {
                  setFieldValue(
                    "service_data",
                    selected.map(val => val.value)
                  );
                } else {
                  setFieldValue("service_data", []);
                }
              }}
            />
            {/* {console.log(errors, "checkerrors")} */}
            <Error field="service_data" />
          </div>
          <div className="form-group">
            <label>
              Venue <span className="red">*</span>
            </label>
            <Select
              value={venueData?.find(val => {
                return values?.venue_data?.includes(val.value);
              })}
              name="venue_data"
              options={venueData}
              // value={values?.venue_data}
              className="basic-multi-select w-100"
              classNamePrefix="select"
              onChange={selected => {
                // console.log(selected, "checkselected");
                if (selected && selected !== null) {
                  console.log("selected", selected);
                  setFieldValue("venue_data", selected.value);
                } else {
                  setFieldValue("venue_data", []);
                }
              }}
            />
            {/* {console.log(errors, "checkerrors")} */}
            <Error field="venue_data" />
          </div>
          <div className="form-group">
            <label>
              Image <span className="red">*</span>
            </label>
            <br />
            {/* <input
                            type="text"
                            className="form-control react-form-input"
                            id="image"
                            onChange={handleChange}
                            value={values.image}
                            onBlur={handleBlur}
                            placeholder="Staff image"
                        /> */}
            <input
              type="file"
              id="upload"
              className="form-control react-form-input"
              accept=".jpg,.png,.jpeg,.svg"
              onChange={e => {
                if (e.target.files[0]) {
                  setFieldValue("file_image", e.target.files[0]);
                  // console.log(e.target.files[0])
                  setPreview(URL.createObjectURL(e.target.files[0]));
                } else {
                  // setFieldValue("currentFile", null);
                  setFieldValue("file_image", "");
                }
              }}
              //   onChange={(e) => onHomeSliderImageChange(e)}
              onBlur={handleBlur}
            />

            {values?.file_image && (
              <>
                <br />
                {preview ? (
                  <img
                    src={preview}
                    // className="detail-img"
                    className="profile-img"
                    alt="default"
                    width={100}
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_DIR}${values?.file_image}`}
                    className="profile-img"
                    alt="no"
                    width={100}
                  />
                )}
                <br />
              </>
            )}
            <Error field="file_image" />
          </div>
          <div className="form-group">
            <label>
              Status <span className="red">*</span>
            </label>
            <select
              className="detail-input-select custom-select"
              value={values?.status}
              name="status"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value={true}>Active</option>
              <option value={false}>Deactive</option>
            </select>
            <Error field="status" />
          </div>
          {/* <div className="form-group">
                        <label>Description</label>
                        <textarea
                            rows={4}
                            className="form-control react-form-input"
                            id="descreption"
                            onChange={handleChange}
                            value={values.descreption}
                            onBlur={handleBlur}
                            placeholder="Description">
                        </textarea>
                        <Error field="descreption" />
                    </div> */}
          <div className="row">
            <div className="col-4 offset-4">
              <Button
                onClick={e => RoomHandler(e)}
                type="submit"
                className="btn form-button modaladdbutton"
                disabled={isValid ? false : true}
              >
                {isEdit ? "Update" : "Add"}
              </Button>
            </div>
            <div className="col-4">
              <Button
                onClick={() => {
                  setPreview(false);
                  onClose();
                }}
                className="btn form-button modalcancelbutton"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
      {loading && <Loader />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(RoomModal);
