import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import { view_room } from "services/roomServices";
import Loader from "components/Loader";
import NavigationActions from "redux/navigation/actions";
import Staffservicetable from "./Staffservicetable";
import Roomservicetable from "./Roomservicetable";
import { ArrowLeft } from "react-feather";
import { getVenueById } from "services/venueServices";

const { success, error } = NavigationActions;
const UniqueRoom = props => {
  const { token, history } = props;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [roomData, setRoomData] = useState({});
  const [serviceData, setServiceData] = useState([]);
  const [venueData, setVenueData] = useState("");
  const [staffData, setStaffData] = useState([]);
  const getUniqueRoom = async () => {
    setLoading(true);
    await view_room(token, id).then(async res => {
      if (res.success) {
        setRoomData(res.data);
        setServiceData(res.data.service_data);
        setStaffData(res.data.staff_data);

        await getVenueById(token, res.data?.venue_data).then(res => {
          if (res.success) {
            setVenueData(res?.data?.name);
          }
        });
        setLoading(false);
      } else {
        setLoading(false);
        error(res.message);
      }
    });
  };

  useEffect(() => {
    getUniqueRoom();
  }, []);
  return (
    <div>
      {
        <>
          <div className="my-5">
            <img
              src={`${process.env.REACT_APP_UPLOAD_DIR}${roomData.file_image}`}
              alt=""
              width={80}
            />
          </div>
          {!loading && (
            <div className="my-5">
              <span className="font-weight-bold mr-2">Room-Username :</span>
              {roomData.username}
            </div>
          )}
          {!loading && (
            <div className="my-5">
              <span className="font-weight-bold mr-2">Venue name :</span>
              {venueData ? venueData : "NA"}
            </div>
          )}
          <div className="my-5">
            {/* {console.log(serviceData.length, "checklength")} */}
            {/* {serviceData && serviceData.length > 0 ? ( */}
            <Roomservicetable serviceData={serviceData} loading={loading} />
            {/* ) : (
              <></>
            )} */}
          </div>
          <div className="my-5">
            {/* {staffData && staffData.length > 0 ? ( */}
            <Staffservicetable staffData={staffData} loading={loading} />
            {/* ) : (
              <></>
            )} */}
          </div>
          {!loading && (
            <div className="my-5">
              <button
                className="btn"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowLeft /> Back
              </button>
            </div>
          )}
        </>
      }
      {loading && <Loader />}
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, { success, error })
)(UniqueRoom);
