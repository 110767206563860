import { connect } from "react-redux";
import React, { useState } from "react";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import enhancer from "./enhancer/addVenueEnhancer";
import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import { useEffect } from "react";
import Loader from "components/Loader";
import { addVenue, editVenue } from "services/venueServices";

const { success, error } = NavigationActions;

const AddVenueModal = props => {
  const { isEdit, editData, onClose } = props;
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    token,
    isValid,
    setValues,
    handleSubmit,
    toggleRefresh
  } = props;
  console.log("isValid", isValid, errors, values);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    isEdit && setValues({ ...editData });
  }, [editData]);
  const addVenueHandler = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      setLoading(true);
      isEdit
        ? await editVenue(token, values._id, values).then(res => {
            if (res.success) {
              success(res.message);
              setLoading(false);
              toggleRefresh(true);
              onClose();
            } else {
              error(res.message);
              setLoading(false);
            }
            setPreview(null);
          })
        : await addVenue(token, values).then(res => {
            if (res.success) {
              success(res.message);
              setLoading(false);
              toggleRefresh(true);
              onClose();
              setPreview(null);
            } else {
              error(res.message);
              setLoading(false);
            }
          });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Vanue
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="form-group">
            <label>
              Name <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              value={values.name}
              onBlur={handleBlur}
              placeholder="Enter venue name"
            />
            <Error field="name" />
          </div>
          <div className="form-group">
            <label>
              Address Line 1 <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="address1"
              onChange={handleChange}
              value={values.address1}
              onBlur={handleBlur}
              placeholder="Address Line 1"
            />
            <Error field="address1" />
          </div>
          <div className="form-group">
            <label>Address Line 2</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="address2"
              onChange={handleChange}
              value={values.address2}
              onBlur={handleBlur}
              placeholder="Enter address line 2"
            />
            <Error field="address2" />
          </div>

          <div className="form-group">
            <label>
              city <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="city"
              onChange={handleChange}
              value={values.city}
              onBlur={handleBlur}
              placeholder="Enter city"
            />
            <Error field="city" />
          </div>
          <div className="form-group">
            <label>
              State <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="state"
              onChange={handleChange}
              value={values.state}
              onBlur={handleBlur}
              placeholder="Enter state"
            />
            <Error field="state" />
          </div>
          <div className="form-group">
            <label>
              Pincode <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="pincode"
              onChange={handleChange}
              value={values.pincode}
              onBlur={handleBlur}
              placeholder="Enter pincode"
            />
            <Error field="pincode" />
          </div>

          <div className="row">
            <div className="col-4 offset-4">
              <Button
                onClick={e => addVenueHandler(e)}
                type="submit"
                className="btn form-button modaladdbutton"
                disabled={isValid ? false : true}
              >
                {isEdit ? "Update" : "Add"}
                {/* {console.log(isValid,values,"isValid")} */}
              </Button>
            </div>
            <div className="col-4">
              <Button
                onClick={() => {
                  setPreview(false);
                  onClose();
                }}
                className="btn form-button modalcancelbutton"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
      {loading ? <Loader /> : ""}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(AddVenueModal);
