import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { compose } from "redux";
import { addtime, edittime, viewtime } from "services/timesettingServices";
import enhancer from "./enhancer/settingEnhancer";
import NavigationActions from "redux/navigation/actions";
import Loader from "components/Loader";

const { success, error } = NavigationActions;
const Setting = props => {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    token,
    isValid,
    setValues,
    handleSubmit
  } = props;
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(false);
  const [refresh, toggleRefresh] = useState(true);

  const getTimeData = async () => {
    await viewtime(token).then(res => {
      if (res.success) {
        setId(res.data[0]?._id);
        setValues({ ...res.data[0] });
        toggleRefresh(false);
      } else {
        error(res.message);
      }
    });
  };
  useEffect(() => {
    refresh && getTimeData();
    //eslint-disable-next-line
  }, [refresh]);
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const handleTimeSubmit = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      setLoading(true);
      id
        ? await edittime(token, id, values).then(res => {
            if (res.success) {
              success(res.message);
              toggleRefresh(true);
              setLoading(false);
            } else {
              error(res.message);
              setLoading(false);
            }
          })
        : await addtime(token, values).then(res => {
            if (res.success) {
              success(res.message);
              toggleRefresh(true);
              setLoading(false);
            } else {
              error(res.message);
              setLoading(false);
            }
          });
    }
  };
  return (
    <>
      <div className="form-group">
        <label>
          Time(Minute)<span className="red">*</span>
        </label>
        <input
          type="text"
          className="form-control react-form-input"
          onChange={handleChange}
          onBlur={handleBlur}
          name="thresholdtime"
          id="thresholdtime"
          value={values.thresholdtime}
          style={{ width: "unset" }}
          placeholder="Please Enter Time Value"
        />
      </div>
      <Error field="thresholdtime" />
      <div className="form-group">
        <Button
          onClick={e => handleTimeSubmit(e)}
          disabled={isValid ? false : true}
          className="btn form-button modaladdbutton"
          style={{ width: "unset" }}
        >
          Save
        </Button>
      </div>
      {loading ? <Loader /> : ""}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(Setting);
