import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Please enter Name"),
    noOfRooms: Yup.number().required("Please enter number of rooms"),
    address1: Yup.string().required("Please enter address line 1"),
    address2: Yup.string(),
    city: Yup.string()
      .matches(/^[A-Z a-z]+$/, "Please enter valid city")
      .required("Please enter city"),
    state: Yup.string(/^[A-Z a-z]+$/, "Please enter valid state").required(
      "Please enter state"
    ),
    pincode: Yup.string()
      .matches(/^[0-9]+$/, "Please enter valid pincode")
      .typeError("Please enter valid pin")
      .min(4)
      .max(10)
      .required("Please enter pincode")
  }),
  mapPropsToValues: props => ({
    name: "",
    noOfRooms: 0,
    address1: "",
    address2: "",
    city: "",
    state: "",
    pincode: "",
    status: false
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
