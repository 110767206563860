import { api, handleResponse, handleError } from "./apiServices";

export const getAllDetailsFinishSetup = token =>
  api(token)
    .get("/finish-setup/get-all-data")
    .then(handleResponse)
    .catch(handleError);

// export const onboeardingDeleteModel = (token, id) =>
//   api(token)
//     .delete(`/onboarding/check/delete/${id}`)
//     .then(handleResponse)
//     .catch(handleError);
