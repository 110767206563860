import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    start_date: Yup.date()
      .required("Please select start date")
      .nullable(),
    end_date: Yup.date()
      .required("Please select end date")
      .nullable()
  }),
  validateOnMount: true,
  mapPropsToValues: props => ({
    start_date: "",
    end_date: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
