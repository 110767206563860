import { connect } from "react-redux";
// import PageTitle from 'components/common/PageTitle';
import React, { useState } from "react";
// import { rowData } from "util/data/reactTableData";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import enhancer from "./enhancer/StaffEnhancer";
import { compose } from "redux";
import { addStaff, editStaff } from "services/staffServices";
import NavigationActions from "redux/navigation/actions";
import { useEffect } from "react";
import Loader from "components/Loader";

const { success, error } = NavigationActions;

const StaffModal = props => {
  const { isEdit } = props;
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    token,
    setFieldValue,
    isValid,
    setValues,
    onClose,
    toggleRefresh,
    handleSubmit,
    editData
  } = props;
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    isEdit && setValues({ ...editData });
  }, [editData]);

  const StaffHandler = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      var formData = new FormData();
      // console.log(values.file_image, "image")
      formData.append("file_image", values.file_image);
      formData.append("name", values.name);
      formData.append("email", values.email.toLowerCase());
      formData.append("phone", values.phone);
      formData.append("status", values.status);
      setLoading(true);
      isEdit
        ? await editStaff(token, values._id, formData).then(res => {
            if (res.success) {
              success(res.message);
              // console.log(res, "edit response")
              toggleRefresh(true);
              onClose();
              setPreview(null);
            } else {
              error(res.message);
            }
            setLoading(false);
            // setValues({});
          })
        : await addStaff(token, formData).then(res => {
            if (res.success) {
              // console.log("add response", res);
              success(res.message);
              toggleRefresh(true);
              onClose();
              setPreview(null);
            } else {
              error(res.message);
            }
            setLoading(false);
            // setValues({});
          });
    }
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Staff
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="form-group">
            <label>
              Name <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              value={values.name}
              onBlur={handleBlur}
              placeholder="Staff Name"
            />
            <Error field="name" />
          </div>
          <div className="form-group">
            <label>
              Email <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              placeholder="Staff email"
              disabled={isEdit ? true : false}
            />
            <Error field="email" />
          </div>
          <div className="form-group">
            <label>
              Phone <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="phone"
              onChange={handleChange}
              value={values.phone}
              onBlur={handleBlur}
              placeholder="Staff phone"
            />
            <Error field="phone" />
          </div>
          <div className="form-group">
            <label>
              Image <span className="red">*</span>
            </label>
            <br />
            {/* <input
                            type="text"
                            className="form-control react-form-input"
                            id="image"
                            onChange={handleChange}
                            value={values.image}
                            onBlur={handleBlur}
                            placeholder="Staff image"
                        /> */}
            <input
              type="file"
              id="upload"
              className="form-control react-form-input"
              accept=".jpg,.png,.jpeg,.svg"
              onChange={e => {
                if (e.target.files[0]) {
                  setFieldValue("file_image", e.target.files[0]);
                  // console.log(e.target.files[0])
                  setPreview(URL.createObjectURL(e.target.files[0]));
                } else {
                  // setFieldValue("currentFile", null);
                  setFieldValue("file_image", "");
                }
              }}
              //   onChange={(e) => onHomeSliderImageChange(e)}
              onBlur={handleBlur}
            />
            {values?.file_image && (
              <>
                <br />
                {preview ? (
                  <img
                    src={preview}
                    // className="detail-img"
                    className="profile-img"
                    alt="default"
                    width={100}
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_DIR}${values?.file_image}`}
                    className="profile-img"
                    alt="no"
                    width={100}
                  />
                )}
                <br />
              </>
            )}
            <Error field="file_image" />
          </div>
          <div className="form-group">
            <label>
              Status <span className="red">*</span>
            </label>
            <select
              className="detail-input-select custom-select"
              value={values?.status}
              name="status"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value={true}>Active</option>
              <option value={false}>Deactive</option>
            </select>
            <Error field="status" />
          </div>
          {/* <div className="form-group">
                        <label>Description</label>
                        <textarea
                            rows={4}
                            className="form-control react-form-input"
                            id="descreption"
                            onChange={handleChange}
                            value={values.descreption}
                            onBlur={handleBlur}
                            placeholder="Description">
                        </textarea>
                        <Error field="descreption" />
                    </div> */}
          <div className="row">
            <div className="col-4 offset-4">
              {console.log(isValid, values, "isvalid values")}
              <Button
                onClick={e => StaffHandler(e)}
                type="submit"
                className="btn form-button modaladdbutton"
                disabled={isValid ? false : true}
              >
                {isEdit ? "Update" : "Add"}
              </Button>
            </div>
            <div className="col-4">
              <Button
                onClick={() => {
                  onClose();
                  setPreview(false);
                }}
                className="btn form-button modalcancelbutton"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
      {loading && <Loader />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(StaffModal);
