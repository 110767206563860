import { api, handleResponse, handleError } from "./apiServices";

export const addtime = (token, data) =>
  api(token)
    .post("api/timesetting/create", data)
    .then(handleResponse)
    .catch(handleError);

export const edittime = (token, id, data) =>
  api(token)
    .post(`api/timesetting/edit/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const viewtime = token =>
  api(token)
    .get(`api/timesetting/view`)
    .then(handleResponse)
    .catch(handleError);
