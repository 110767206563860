import { api, handleResponse, handleError } from "./apiServices";

export const requestData = data =>
  api()
    .post(`/api/request/getall-details`, data)
    .then(handleResponse)
    .catch(handleError);

export const getallrequestData = (token, data) =>
  api(token)
    .post(`/api/request/getallrequest`, data)
    .then(handleResponse)
    .catch(handleError);

export const changerequestfromweb = data =>
  api()
    .post(`/api/request/approve-rejectformweb`, data)
    .then(handleResponse)
    .catch(handleError);

export const getExcelReport = (token, data) =>
  api(token)
    .post(`/api/request/servicereport`, data)
    .then(handleResponse)
    .catch(handleError);
