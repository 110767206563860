import { api, handleResponse, handleError } from "./apiServices";

export const get_superadmin_profile = token =>
  api(token)
    .get("/api/v1/superadmin/profile")
    .then(handleResponse)
    .catch(handleError);

export const update_admin_profile = (token, data) =>
  api(token)
    .put("/api/v1/superadmin/editprofile", data)
    .then(handleResponse)
    .catch(handleError);

export const change_password = (token, id, data) =>
  api(token)
    .post(`/api/v1/superadmin/change-password`, data)
    .then(handleResponse)
    .catch(handleError);



export const resetPassword = data =>
  api()
    .post(`/api/v1/superadmin/resetpassword`, data)
    .then(handleResponse)
    .catch(handleError);

export const forgotPassword = data =>
  api()
    .post("/api/v1/superadmin/forgetpassword", data)
    .then(handleResponse)
    .catch(handleError);

export const send_access_key = (token, id) =>
  api(token)
    .post(`/api/v1/superadmin/send_access_key`,id)
    .then(handleResponse)
    .catch(handleError);



// export const send_access_key = (token, id, data) =>
// api(token)
//   .post(`/api/v1/superadmin/change-password`, data)
//   .then(handleResponse)
//   .catch(handleError);

