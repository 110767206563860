import { api, handleResponse, handleError } from "./apiServices";

export const getUserAllNotifications = (token, params) =>
  api(token)
    .post(`/user/notification/all`, params)
    .then(handleResponse)
    .catch(handleError);

export const markUserNoticationsRead = token =>
  api(token)
    .post(`/user/notifications/mark/read`)
    .then(handleResponse)
    .catch(handleError);

export const markEscalatNotificationReadById = (token, id) =>
  api(token)
    .post(`/user/notification/mark/read/${id}`)
    .then(handleResponse)
    .catch(handleError);

// export const deleteUserNotificationById = (token, id) =>
//   api(token)
//     .delete(`/user/notification/delete/${id}`)
//     .then(handleResponse)
//     .catch(handleError);

export const unreadNotificationsCount = token =>
  api(token)
    .get(`/user/notifications/unread/count`)
    .then(handleResponse)
    .catch(handleError);
