import { api, handleResponse, handleError } from "./apiServices";

export const getService = (token, data) =>
  api(token)
    .post("api/service/getAll", data)
    .then(handleResponse)
    .catch(handleError);

export const getServiceAllData = token =>
  api(token)
    .get("api/service/getAllserviceData")
    .then(handleResponse)
    .catch(handleError);

export const addService = (token, data) =>
  api(token)
    .post("api/service/create", data)
    .then(handleResponse)
    .catch(handleError);

export const editService = (token, id, data) =>
  api(token)
    .put(`api/service/edit/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const deleteService = (token, id) =>
  api(token)
    .delete(`api/service/delete/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const changeServiceStatus = (token, id, data) =>
  api(token)
    .put(`/api/service/update-status/${id}`, data)
    .then(handleResponse)
    .catch(handleError);
