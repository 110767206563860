import { connect } from "react-redux";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import Pagination from "@mui/material/Pagination";
import classnames from "classnames";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import AddVenueModal from "./AddVenueModal";
import enhancer from "./enhancer/addVenueEnhancer";
import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import { Button, Modal } from "reactstrap";
import ConformationModaluser from "components/common/ConformationModalUser";
import { Switch } from "@mui/material";
import Loader from "components/Loader";
import { getVenues, deleteVenue, changeStatus } from "services/venueServices";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const { success, error } = NavigationActions;

const VenueList = props => {
  const { sidebarTheme, token, history } = props;
  const activeColor = {
    color: sidebarTheme.activeColor
  };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, toggleRefresh] = useState(true);
  const [openDeleteModal, toggleDeleteModalOpen] = useState();
  const [deleteId, setDeleteID] = useState("");
  const [venueList, setVenueList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState();
  const [pageCng, setPageCng] = useState(1);
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [dataLength, setDataLength] = useState(0);
  const [sortObject, setSortObject] = useState({});

  const getVenueHandler = useCallback(async () => {
    setLoading(true);
    const data = {
      limit: 10,
      page: pageCng,
      search: search,
      sortBy: sortObject.id,
      sortOrder: sortObject?.desc ? -1 : 1
    };
    await getVenues(token, data)
      .then(res => {
        setVenueList(res.data.docs);
        setPageCount(res.data.totalPages);
        setDataLength(res.data.docs.length);
        toggleRefresh(false);
        setLoading(false);
      })
      .catch(err => {
        error(err.message);
        setLoading(false);
      });
  });

  useEffect(() => {
    if (search !== undefined) {
      const timeOutId = setTimeout(() => getVenueHandler(), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  const handlePageChange = (e, page) => {
    console.log(page, "page check");
    setPageCng(page);
    toggleRefresh(true);
  };

  const deleteVenueHandler = async () => {
    setLoading(true);
    await deleteVenue(token, deleteId).then(res => {
      if (res.success) {
        success(res.message);
        toggleRefresh(true);
        toggleDeleteModalOpen(false);
        setPageCng(
          dataLength === 1 ? (pageCng !== 1 ? pageCng - 1 : pageCng) : pageCng
        );
      } else {
        error(res.message);
      }
      setLoading(false);
    });
  };

  const chageStatus = (status, id) => {
    setLoading(true);
    setShow(false);
    setLoading(true);

    changeStatus(token, id, { status: status })
      .then(res => {
        if (res.success) {
          success(res.message);
          toggleRefresh(true);
        } else {
          error(res.message);
        }
        setShow(true);
        setLoading(false);
      })
      .catch(err => {
        setShow(true);
        setLoading(true);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Name"
            />
          );
        },
        placeholder: "Names",
        accessor: "name",
        Cell: tableInstance => (
          <span className="text-capitalize">
            {tableInstance.row.original?.name}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Number Of Rooms"
            />
          );
        },
        placeholder: "Number Of Rooms",

        accessor: "numberOfRooms",

        Cell: tableInstance => (
          <span className="text-capitalize">{tableInstance.value}</span>
        )
      },
      // {
      //   Header: tableInstance => {
      //     return (
      //       <HeaderComponent
      //         isSortedDesc={tableInstance.column.isSortedDesc}
      //         title="Status"
      //       />
      //     );
      //   },
      //   placeholder: "Status",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   accessor: "status",
      //   Cell: tableInstance => (
      //     <span>
      //       {show && (
      //         <Switch
      //           checked={tableInstance?.row?.values?.status}
      //           onChange={e =>
      //             chageStatus(
      //               e.target.checked,
      //               tableInstance?.row?.original?._id
      //             )
      //           }
      //           inputProps={{ "aria-label": "controlled" }}
      //         />
      //       )}
      //     </span>
      //   )
      // },

      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title=""
            />
          );
        },
        accessor: "view rooms",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div>
              <Button
                color="success"
                onClick={() => {
                  history.push(`venue/${tableInstance.row.original._id}/rooms`);
                }}
              >
                View rooms
              </Button>
            </div>
          );
        }
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class">
              <button
                className="react-table-edit-button"
                onClick={() => {
                  setModal(true);
                  setIsEdit(true);
                  setEditData(tableInstance.row.original);
                }}
              >
                <i className="fas fa-edit" />
              </button>
              <button
                className="react-table-delete-button"
                onClick={() => {
                  toggleDeleteModalOpen(true);
                  setDeleteID(tableInstance.row.original?._id);
                }}
              >
                <i className="fas fa-trash" />
              </button>
            </div>
          );
        }
      }
    ],
    [venueList]
  );
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    state: { sortBy }
  } = useTable(
    {
      data: venueList,
      columns: columns,
      manualSortBy: true,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );
  useEffect(() => {
    console.log("inside this use effect", refresh);

    refresh && getVenueHandler();
  }, [refresh, sortBy, pageCng]);

  useEffect(() => {
    setSortObject({ ...sortBy[0] });
    console.log("sortObject", { ...sortBy[0] });
    toggleRefresh(true);
  }, [sortBy, pageCng]);

  return (
    <>
      <div>
        <div className="plr-15">
          <div className="mtb-30 theme-color">
            <div className="row">
              <div className="col-10">
                <div className="introduction" style={activeColor}>
                  Venues
                </div>
              </div>
              <div className="col-2">
                <div style={{ width: "initial" }}>
                  <button
                    type="submit"
                    className="c-primary btn btn-secondary w-100 addbtncolor"
                    onClick={() => {
                      setIsEdit(false);
                      setModal(true);
                    }}
                  >
                    Add Venue
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <input
                  className="form-control react-form-input mt-2"
                  type="search"
                  placeholder="Search venue"
                  onChange={e => {
                    setPageCng(1);
                    setSearch(e.target.value);
                  }}
                />
              </div>
              <div className="col-12">
                {
                  <ReactTableWrapper {...props}>
                    <div className="table-container text-left overflow-auto mt-4">
                      <table
                        border={1}
                        className="custom-react-table-theme-class"
                        {...getTableProps()}
                      >
                        <thead>
                          {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map(header => (
                                <th
                                  className="text-center"
                                  {...header.getHeaderProps(
                                    header.getSortByToggleProps()
                                  )}
                                >
                                  <div>{header.render("Header")}</div>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        {loading ? (
                          <tbody>
                            <tr>
                              <td>
                                <Loader />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody {...getTableBodyProps()}>
                            {venueList.length > 0 ? (
                              page.map(row => {
                                prepareRow(row);
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                      <td
                                        className="text-center"
                                        {...cell.getCellProps()}
                                      >
                                        {cell.render("Cell")}
                                      </td>
                                    ))}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                {
                                  <td
                                    colSpan={4}
                                    className="mt-4  ml-5 text-center font-weight-bold"
                                  >
                                    <h5>No data found</h5>
                                  </td>
                                }
                              </tr>
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div className="float-right">
                      <Pagination
                        onChange={handlePageChange}
                        count={pageCount}
                        page={pageCng}
                      />
                    </div>
                  </ReactTableWrapper>
                }
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={modal} backdrop={true}>
          {modal && (
            <AddVenueModal
              isEdit={isEdit}
              editData={editData}
              onClose={() => {
                setModal(false);
                setIsEdit(false);
                setEditData({});
              }}
              toggleRefresh={e => toggleRefresh(e)}
            />
          )}
        </Modal>
        <Modal isOpen={openDeleteModal} backdrop={true}>
          {openDeleteModal && (
            <ConformationModaluser
              isOpen={openDeleteModal}
              onClose={() => toggleDeleteModalOpen(false)}
              confirmText={"Delete"}
              message={"Are you sure you want to delete?"}
              handleConfirm={() => deleteVenueHandler()}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(VenueList);
