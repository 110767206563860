import { api, handleResponse, handleError } from "./apiServices";

export const getRoom = (token, data) =>
  api(token)
    .post("api/room/getAll", data)
    .then(handleResponse)
    .catch(handleError);

export const getallRooms = (token, data) =>
  api(token)
    .get("api/room/getallrooms", data)
    .then(handleResponse)
    .catch(handleError);

export const addRoom = (token, data) =>
  api(token)
    .post("api/room/create", data)
    .then(handleResponse)
    .catch(handleError);

export const editRoom = (token, id, data) =>
  api(token)
    .put(`api/room/edit/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const deleteRoom = (token, id) =>
  api(token)
    .delete(`api/room/delete/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const changeRoomStatus = (token, id, data) =>
  api(token)
    .put(`/api/room/update-status/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const send_room_key = (token, id) =>
  api(token)
    .post(`/api/room/send_room_key`, id)
    .then(handleResponse)
    .catch(handleError);

export const view_room = (token, id) =>
  api(token)
    .get(`/api/room/view/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const getRoomsByVenue = (token, id, data) =>
  api(token)
    .get(`/api/room/getRoomsByVenue/${id}`, { params: data })
    .then(handleResponse)
    .catch(handleError);
