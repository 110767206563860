import { connect } from "react-redux";
import React, { useState } from "react";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import enhancer from "./enhancer/SliderImageEnchaner";
import { compose } from "redux";
import { addImages, editImage } from "services/imageSliderServices";
import NavigationActions from "redux/navigation/actions";
import { useEffect } from "react";
import Loader from "components/Loader";
const { success, error } = NavigationActions;

const SliderImageModal = props => {
  const { isEdit, onClose } = props;
  const {
    values,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    isValid,
    setValues,
    token,
    handleSubmit,
    toggleRefresh,
    editData
  } = props;
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    isEdit && setValues({ ...editData });
  }, [editData]);

  const ImageHandler = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      var formData = new FormData();
      formData.append("file_image", values.file_image);
      setLoading(true);

      isEdit
        ? await editImage(token, values._id, formData).then(res => {
            if (res.success) {
              success(res.message);
              toggleRefresh(true);
              onClose();
              setLoading(false);
            } else {
              error(res.message);
              setLoading(false);
            }
            setLoading(false);
            setPreview(null);
          })
        : await addImages(token, formData).then(res => {
            if (res.success) {
              success(res.message);
              toggleRefresh(true);
              onClose();
              setLoading(false);
            } else {
              error(res.message);
              setLoading(false);
            }
            setPreview(null);
            setLoading(false);
          });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Room
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="form-group">
            <label>
              Image <span className="red">*</span>
            </label>
            <br />
            <input
              type="file"
              id="file_image"
              className="form-control react-form-input"
              accept=".jpg,.png,.jpeg,.svg,.gif"
              onChange={e => {
                if (e.target.files[0]) {
                  setFieldValue("file_image", e.target.files[0]);
                  setPreview(URL.createObjectURL(e.target.files[0]));
                } else {
                  setFieldValue("file_image", "");
                }
              }}
              onBlur={handleBlur}
            />

            {values?.file_image && (
              <>
                <br />
                {preview ? (
                  <img
                    src={preview}
                    className="profile-img"
                    alt="default"
                    width={100}
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_DIR}${values?.file_image}`}
                    className="profile-img"
                    alt="no"
                    width={100}
                  />
                )}
                <br />
              </>
            )}
            <Error field="file_image" />
          </div>
          <div className="row">
            <div className="col-4 offset-4">
              <Button
                onClick={e => ImageHandler(e)}
                type="submit"
                className="btn form-button modaladdbutton"
                disabled={isValid ? false : true}
              >
                {isEdit ? "Update" : "Add"}
              </Button>
            </div>
            <div className="col-4">
              <Button
                onClick={() => {
                  setPreview(false);
                  onClose();
                }}
                className="btn form-button modalcancelbutton"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
      {loading && <Loader />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(SliderImageModal);
