import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Please Enter Email"),
    phone_number: Yup.string()
      .matches(/^\d+$/, "Please Enter valid Phone Number")
      .length(10, "Please Enter valid phone number")
      .required("Please Enter Phone Number")
  }),
  mapPropsToValues: props => ({
    name: "",
    email: "",
    phone_number: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
