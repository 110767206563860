import StaffList from "views/pages/staff/StaffList";
import RoomList from "views/pages/rooms/RoomList";
import ServiceList from "views/pages/services/ServiceList";
import EditProfile from "views/pages/admin/EditProfile";
import ChangePassword from "views/pages/admin/ChangePassword";
import UniqueRoom from "views/pages/rooms/UniqueRoom";
import Setting from "views/pages/setting/setting";
import RequestList from "views/pages/request/RequestList";
import SliderImageList from "views/pages/sliderimage/SliderImageList";
import Reports from "views/pages/reports/Reports";
import VenueList from "views/pages/venue/VenueList";
import RoomListByVenue from "views/pages/rooms/RoomListByVenue";

const dashboardRoutes = [
  { path: "/staff", component: StaffList },
  { path: "/rooms", component: RoomList },
  { path: "/room/:id", component: UniqueRoom },
  { path: "/services", component: ServiceList },
  { path: "/change-password", component: ChangePassword },
  { path: "/profile", component: EditProfile },
  { path: "/setting", component: Setting },
  { path: "/slider", component: SliderImageList },
  { path: "/request", component: RequestList },
  { path: "/thresouldtime", component: RequestList },
  { path: "/report", component: Reports },
  { path: "/venue", component: VenueList },
  { path: "/venue/:id/rooms", component: RoomListByVenue }
];
export default dashboardRoutes;
