import { api, handleResponse, handleError } from "./apiServices";

export const addImages = (token, data) =>
  api(token)
    .post("/api/images/uploadimage", data)
    .then(handleResponse)
    .catch(handleError);

export const getImages = (token, data) =>
  api(token)
    .get("/api/images/getimages", data)
    .then(handleResponse)
    .catch(handleError);

export const editImage = (token, id, data) =>
  api(token)
    .put(`api/images/editimage/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const deleteImage = (token, id) =>
  api(token)
    .delete(`api/images/deleteimage/${id}`)
    .then(handleResponse)
    .catch(handleError);
