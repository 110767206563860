import { withFormik } from "formik";
import * as Yup from "yup";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/svg",
  "image/gif"
];

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    file_image: Yup.mixed()
      .required("Please upload a image")
      .test(
        "fileFormat",
        "Only JPG, JPEG, PNG, SVG and GIF files are allowed.",
        value => value && SUPPORTED_FORMATS.includes(value?.type)
      )
  }),
  mapPropsToValues: props => ({
    file_image: "",
    status: true
  }),
  validateOnMount: true,
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
