import { api, handleResponse, handleError } from "./apiServices";

export const addVenue = (token, data) =>
  api(token)
    .post(`api/venue/`, data)
    .then(handleResponse)
    .catch(handleError);
export const getVenues = (token, data) =>
  api(token)
    .get(`api/venue/`, { params: data })
    .then(handleResponse)
    .catch(handleError);
export const getVenueById = (token, id) =>
  api(token)
    .get(`api/venue/${id}`)
    .then(handleResponse)
    .catch(handleError);
export const deleteVenue = (token, id) =>
  api(token)
    .delete(`api/venue/${id}`)
    .then(handleResponse)
    .catch(handleError);
export const editVenue = (token, id, data) =>
  api(token)
    .put(`api/venue/${id}`, data)
    .then(handleResponse)
    .catch(handleError);
export const changeStatus = (token, id, data) =>
  api(token)
    .patch(`api/venue/${id}`, data)
    .then(handleResponse)
    .catch(handleError);
export const getAllVenues = token =>
  api(token)
    .get(`api/venue/get-all-venues`)
    .then(handleResponse)
    .catch(handleError);
