import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { compose } from "redux";
import enhancer from "./enhancer/reportEnhancer";
import NavigationActions from "redux/navigation/actions";
import Loader from "components/Loader";
import DatePicker from "react-datepicker";
// import DatepickerWrapper from "components/forms/alldatepickers/datepicker.style";
import "react-datepicker/dist/react-datepicker.css";
import { getExcelReport } from "services/requestServices";
import moment from "moment";

const { success, error } = NavigationActions;
const Reports = props => {
  const {
    values,
    handleBlur,
    errors,
    touched,
    submitCount,
    isValid,
    handleSubmit,
    setFieldValue,
    resetForm,
    token
  } = props;
  const [loading, setLoading] = useState(false);
  const [userTimezone, setUserTimezone] = useState("");
  // const [id, setId] = useState(false);
  // const [refresh, toggleRefresh] = useState(true);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const handleReportSubmit = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      setLoading(true);
      await getExcelReport(token, {
        start_date: values.start_date,
        end_date: moment(values.end_date).endOf("day"),
        userTimezone: userTimezone
      }).then(res => {
        if (res.message === "Success") {
          console.log(
            res,
            "check50",
            `${process.env.REACT_APP_BACKEND_URL}/${res.data}`
          );
          var createA = document.createElement("a");
          createA.setAttribute(
            "href",
            `${process.env.REACT_APP_BACKEND_URI}/${res.data}`
          );
          createA.setAttribute("download", "Reports.xlsx");
          document.body.appendChild(createA);
          createA.click();
          // resetForm();
          setLoading(false);
        } else {
          error(res.message);
          setLoading(false);
        }
      });
    }
  };
  useEffect(() => {
    const userTime = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimezone(userTime);
  }, []);
  // useEffect(() => {
  //   refresh && handleReportSubmit();
  // }, [])
  return (
    <>
      <div className="row mx-0">
        <div className="col-12 col-sm-auto form-group">
          <div>
            <label>
              Start Date<span className="red">*</span>
            </label>
          </div>
          <DatePicker
            peekPriviousMonth
            showMonthDropdown
            showYearDropdown
            name="start_date"
            id="start_date"
            selected={values.start_date}
            dateFormat="yyyy/MM/dd"
            className="form-control react-form-input"
            value={values.start_date}
            onChange={date => {
              setFieldValue("start_date", date);
              setFieldValue("end_date", "");
            }}
            onBlur={handleBlur}
            maxDate={new Date()}
          />
          <div>
            <Error field="start_date" />
          </div>
        </div>
        <div className="col-12 col-sm-auto form-group">
          <div>
            <label>
              End Date<span className="red">*</span>
            </label>
          </div>
          <DatePicker
            peekPriviousMonth
            showMonthDropdown
            showYearDropdown
            name="end_date"
            id="end_date"
            selected={values.end_date}
            dateFormat="yyyy/MM/dd"
            className="form-control react-form-input"
            onChange={date => {
              setFieldValue("end_date", date);
            }}
            onBlur={handleBlur}
            minDate={values.start_date}
            maxDate={new Date()}
          />
          <div>
            <Error field="end_date" />
          </div>
        </div>
      </div>
      <div className="col form-group">
        <Button
          onClick={e => handleReportSubmit(e)}
          disabled={isValid ? false : true}
          className="btn form-button modaladdbutton"
          style={{ width: "unset" }}
        >
          Generate Report
        </Button>
      </div>
      {loading ? <Loader /> : ""}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(Reports);
