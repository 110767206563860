import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    username: Yup.string().required("Please Enter User Name"),
    password: Yup.string()
      .min(8, "Password must be atleast 8 characters.")
      .required("Please Enter Password"),
    file_image: Yup.string().required("Please upload image"),
    staff_data: Yup.array().min(1, "Please select staff"),
    service_data: Yup.array().min(1, "Please select service"),
    venue_data: Yup.string().required("Please select venue data"),
    status: Yup.string().required("Please select status")
  }),
  mapPropsToValues: props => ({
    name: "",
    username: "",
    password: "",
    file_image: "",
    staff_data: [],
    service_data: [],
    venue_data: "",
    status: true
  }),
  validateOnMount: true,
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
