import { connect } from "react-redux";

import React, { useState, useMemo, useEffect, useCallback } from "react";

import { useTable, useSortBy, useFilters, usePagination } from "react-table";

import classnames from "classnames";
import Pagination from "components/common/Pagination";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import SliderImageModal from "./SliderImageModal";
import enhancer from "./enhancer/SliderImageEnchaner";
import { compose } from "redux";
import { getImages, deleteImage } from "services/imageSliderServices";
import NavigationActions from "redux/navigation/actions";
import { Modal } from "reactstrap";
import ConformationModaluser from "components/common/ConformationModalUser";
import { withRouter } from "react-router-dom";
import Loader from "components/Loader";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const { success, error } = NavigationActions;

const SliderImageList = props => {
  const { sidebarTheme, token } = props;

  const activeColor = {
    color: sidebarTheme.activeColor
  };

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDeleteModal, toggleDeleteModalOpen] = useState();
  const [deleteId, setDeleteID] = useState("");
  const [imageList, setImageList] = useState([]);
  const [pageCng, setPageCng] = useState(1);
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [refresh, toggleRefresh] = useState(false);

  const getImageHandler = useCallback(async () => {
    const data = {};
    setLoading(true);
    await getImages(token, data)
      .then(res => {
        // console.log(res, "res");
        setImageList(res.data);
        setLoading(false);
      })
      .catch(err => {
        error(err.message);
        setLoading(false);
      });
  }, [pageCng]);

  useEffect(() => {
    getImageHandler();
  }, [pageCng, show]);

  useEffect(() => {
    refresh && getImageHandler() && toggleRefresh(false);
  }, [refresh]);

  const deleteImageHandler = async () => {
    setLoading(true);
    await deleteImage(token, deleteId).then(res => {
      if (res.success) {
        success(res.message);
        toggleDeleteModalOpen(false);
        toggleRefresh(true);
      } else {
        error(res.message);
      }
      setLoading(false);
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Image"
            />
          );
        },

        placeholder: "image",
        accessor: "file_image",
        Cell: tableInstance => (
          <span>
            <img
              src={`${process.env.REACT_APP_UPLOAD_DIR}${tableInstance.row.original.file_image}`}
              className="profile-img"
              alt="image"
              width={60}
            />
            {/* {console.log(tableInstance.row.original.file_image, "file_image")} */}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class">
              <button
                className="react-table-edit-button"
                onClick={() => {
                  setModal(true);
                  setIsEdit(true);
                  setEditData(tableInstance.row.original);
                }}
              >
                <i className="fas fa-edit" />
              </button>
              <button
                className="react-table-delete-button"
                onClick={() => {
                  toggleDeleteModalOpen(true);
                  setDeleteID(tableInstance.row.original?._id);
                }}
              >
                <i className="fas fa-trash" />
              </button>
            </div>
          );
        }
      }
    ],
    [imageList]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      data: imageList,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="plr-15">
            <div className="mtb-30 theme-color">
              <div className="row">
                <div className="col-10">
                  <div className="introduction" style={activeColor}>
                    Image Slider
                  </div>
                </div>
                <div className="col-2">
                  <div style={{ width: "initial" }}>
                    <button
                      type="submit"
                      className="c-primary btn btn-secondary w-100 addbtncolor"
                      onClick={() => {
                        setIsEdit(false);
                        setModal(true);
                      }}
                    >
                      Add Images
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <ReactTableWrapper {...props}>
                    <div className="table-container text-left overflow-auto mt-4">
                      <table
                        border={1}
                        className="custom-react-table-theme-class"
                        {...getTableProps()}
                      >
                        <thead>
                          {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map(header => (
                                <th
                                  {...header.getHeaderProps(
                                    header.getSortByToggleProps()
                                  )}
                                >
                                  <div>{header.render("Header")}</div>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {imageList.length > 0 ? (
                            page.map(row => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  ))}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="mt-4 text-left font-weight-bold">
                                <h5>No data found</h5>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="float-right">
                      <Pagination
                        onPageChange={gotoPage}
                        pages={pageCount}
                        page={pageIndex}
                      />
                    </div>
                  </ReactTableWrapper>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={modal} backdrop={true}>
            {modal && (
              <SliderImageModal
                isEdit={isEdit}
                editData={editData}
                onClose={() => {
                  setModal(false);
                  setIsEdit(false);
                  setEditData({});
                }}
                toggleRefresh={e => toggleRefresh(e)}
              />
            )}
          </Modal>
          <Modal isOpen={openDeleteModal} backdrop={true}>
            {/* {console.log("called459", openDeleteModal)} */}
            {openDeleteModal && (
              <ConformationModaluser
                isOpen={openDeleteModal}
                onClose={() => toggleDeleteModalOpen(false)}
                confirmText={"Delete"}
                message={"Are you sure you want to delete?"}
                handleConfirm={() => deleteImageHandler()}
              />
            )}
          </Modal>
          {/* {console.log("opendeleteModal", openDeleteModal)} */}
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(SliderImageList);
